import moment from 'moment'

import LemonwayLogo from 'images/payment_methods/elopage_lite.svg'
import MangopayLogo from 'images/payment_methods/elopage_plus.svg'
import PayPalLogo from 'images/payment_methods/Paypal.svg'
import StripeLogo from 'images/payment_methods/Stripe-blue.svg'
import ElopageConnect from 'images/payment_methods/ElopageConnect.svg'
import Inkasso from 'images/payment_methods/Inkasso.svg'

import Ideal from 'images/payment_methods/Ideal.svg'
import IdealSepa from 'images/payment_methods/IdealSepa.svg'
import Klarna from 'images/payment_methods/klarna.svg'
import { PAYMENT_PROFILE_TYPES } from './profile.constants'

import {
  PAYMENT_FORMS,
  PAYMENT_PROVIDERS,
  PAYMENT_PROVIDERS_LIST,
  PAYMENT_FORMS_LOGOS,
  PAYMENT_SYSTEMS,
  PAYPAL_PROVIDERS,
} from './paymentSettingShared.constants'

export * from './paymentSettingShared.constants'

export const P24_PAYMENT_KEY = 'p24'

export const PAYMENT_METHODS_LOGOS = {
  lemonWay: LemonwayLogo,
  mangoPay: MangopayLogo,
  payPal: PayPalLogo,
  stripe: StripeLogo,
  elopageConnect: ElopageConnect,
  ideal: Ideal,
  ideal_sepa: IdealSepa,
  inkasso: Inkasso,
  klarna: Klarna,
}

export const LEGITIMATION_PROVIDERS = {
  lemonWay: 'lemonway',
  mangoPay: 'mangopay',
  elopageConnect: 'elopage_connect',
}
export const LEGITIMATION_PROVIDERS_LIST = Object.values(LEGITIMATION_PROVIDERS)

export const PAYMENT_FORMS_DESCRIPTIONS = {
  ext_balance: I18n.t('react.cabinet.billing.subscription_side.payment_descriptions.ext_balance'),
  additional_ext_balance_info: I18n.t(
    'react.cabinet.billing.subscription_side.payment_descriptions.additional_ext_balance_info'
  ),
  bank_wire: I18n.t('react.cabinet.billing.subscription_side.payment_descriptions.bank_wire'),
  card: I18n.t('react.cabinet.billing.subscription_side.payment_descriptions.card'),
  sepa: I18n.t('react.cabinet.billing.subscription_side.payment_descriptions.sepa'),
}

export const PAYMENT_SETTING_LOGOS = {
  ...PAYMENT_METHODS_LOGOS,
  ...PAYMENT_FORMS_LOGOS,
}

export const PAYMENT_SETTING_LOGOS_HEIGHT = {
  /* Setup Page */
  p_24: 19,
  /* Settings Page */
  p24: 24,
  sofort: 38,
  sofort_sepa: 26,
  card: 20,
  ideal: 45,
  ideal_sepa: 26,
  klarna: 35,
}

export const PAYMENT_SELECTOR_LOGOS_HEIGHT = {
  p_24: {
    height: 17,
    maxHeight: 20,
  },
  sofort: {
    height: 20,
    maxHeight: 20,
  },
  card: {
    height: 20,
    maxHeight: 20,
  },
  ideal: {
    height: 18,
    maxHeight: 18,
  },
  klarna: {
    height: 18,
    maxHeight: 18,
  },
}

export const LEGITIMATION_USER_PROFILE_FIELDS = [
  'birthday',
  'city',
  'countryCode',
  'firstName',
  'lastName',
  'nationality',
  'street',
  'streetNumber',
  'zip',
]
export const HOLDER_ADDRESS_FIELDS = ['city', 'countryCode', 'region', 'street', 'streetNumber', 'zip']
export const BANK_ACCOUNT_HOLDER_ADDRESS_FIELDS = ['address', 'city', 'country', 'region', 'zip']

export const LEGITIMATION_DOCUMENTS_TYPES = {
  lemonWayDocument: 'LemonWayDocument',
  mangoPayDocument: 'MangoPayDocument',
  mangoPayUbo: 'MangoPayUbo',
}

export const PAYMENT_SETTINGS_TABS = [
  {
    key: 'providers',
    link: '/cabinet/settings/payment_providers',
    label: I18n.t('react.cabinet.payment_methods.tabs.providers'),
  },
  {
    key: 'matrix',
    link: '/cabinet/settings/payment_settings',
    label: I18n.t('react.cabinet.payment_methods.tabs.matrix'),
  },
]

export const LEMONWAY_PAYMENT_SYSTEMS = [
  PAYMENT_FORMS.bankWire,
  PAYMENT_FORMS.card,
  // PAYMENT_FORMS.sofort,
]

export const MANGOPAY_PAYMENT_SYSTEMS = [PAYMENT_FORMS.bankWire, PAYMENT_FORMS.card, PAYMENT_FORMS.sofort]

export const LEMONWAY_LEGITIMATION_REQUIRED_FILE_TYPES = [
  'id_from_front',
  'id_from_back',
  'proof_doc',
  'company_lr',
  'article_doc',
]
export const LEMONWAY_LEGITIMATION_FILE_TYPES = [
  ...LEMONWAY_LEGITIMATION_REQUIRED_FILE_TYPES,
  'other_doc_1',
  'other_doc_2',
]

export const LEMONWAY_LEGITIMATION_USER_PROFILE_FIELDS = [...LEGITIMATION_USER_PROFILE_FIELDS, 'phone']

export const PAYPAL_PROVIDERS_LIST = Object.values(PAYPAL_PROVIDERS)
export const PAYPAL_PROVIDERS_TABS = PAYPAL_PROVIDERS_LIST.map((key) => ({
  key,
  label: key.toUpperCase(),
}))

export const PAYPAL_FIELDS = {
  [PAYPAL_PROVIDERS.rest]: ['email', 'clientId', 'clientSecret', 'merchantId'],
  [PAYPAL_PROVIDERS.nvp]: ['user', 'password', 'signature'],
}

export const STRIPE_FIELDS = ['publishableKey', 'secretKey']

export const PAYMENT_SETTINGS = [...PAYMENT_PROVIDERS_LIST, ...PAYPAL_PROVIDERS_LIST]

export const ALLOWED_PAYMENT_PROVIDERS = {
  card: PAYMENT_PROVIDERS_LIST,
  payPal: PAYPAL_PROVIDERS_LIST,
  sofort: [PAYMENT_PROVIDERS.stripe, PAYMENT_PROVIDERS.mangoPay, PAYMENT_PROVIDERS.elopageConnect],
  sepa: [PAYMENT_PROVIDERS.mangoPay, PAYMENT_PROVIDERS.stripe, PAYMENT_PROVIDERS.elopageConnect],
  bankWire: [PAYMENT_PROVIDERS.lemonWay, PAYMENT_PROVIDERS.mangoPay, PAYMENT_PROVIDERS.elopageConnect],
  p24: [PAYMENT_PROVIDERS.stripe, PAYMENT_PROVIDERS.elopageConnect],
  applePay: [PAYMENT_PROVIDERS.stripe, PAYMENT_PROVIDERS.elopageConnect],
  googlePay: [PAYMENT_PROVIDERS.stripe, PAYMENT_PROVIDERS.elopageConnect],
  ideal: [PAYMENT_PROVIDERS.stripe, PAYMENT_PROVIDERS.elopageConnect],
}

export const KLARNA_PAYMENT_PROVIDER = {
  klarna: [PAYMENT_PROVIDERS.stripe, PAYMENT_PROVIDERS.elopageConnect],
}

export const KLARNA_PAYMENT_PROVIDER_KEYS = Object.keys(KLARNA_PAYMENT_PROVIDER)

export const ALLOWED_PAYMENT_PROVIDERS_KEYS = Object.keys(ALLOWED_PAYMENT_PROVIDERS)

/** reseller request */
export const RESELLER_REQUEST_FORMS = {
  open: 'open',
  close: 'close',
}

export const getResellerModeLocales = () => ({
  'descriptions.close_rejected': I18n.t('react.cabinet.payment_methods.reseller.descriptions.close_rejected'),
  'descriptions.close_waiting': I18n.t('react.cabinet.payment_methods.reseller.descriptions.close_waiting'),
  'descriptions.description_after': I18n.t('react.cabinet.payment_methods.reseller.descriptions.description_after'),
  'descriptions.description_before': I18n.t('react.cabinet.payment_methods.reseller.descriptions.description_before'),
  'descriptions.open_rejected': I18n.t('react.cabinet.payment_methods.reseller.descriptions.open_rejected'),
  'descriptions.open_waiting': I18n.t('react.cabinet.payment_methods.reseller.descriptions.open_waiting'),
})

export const SELLING_FORMS = {
  sellingNone: 'selling_none',
  sellingByOwnself: 'selling_by_ownself',
  sellingViaReseller: 'selling_via_reseller',
}

export const RESELLER_REQUEST_STATUSES = {
  rejected: 'rejected',
  approved: 'approved',
  canceled: 'canceled',
  waiting: 'waiting',
}

export const MANGOPAY_COMPANY_FORMS = {
  soleTrader: 'sole_trader',
  business: 'business',
  organization: 'organization',
}
export const MANGOPAY_COMPANY_FORMS_LIST = Object.values(MANGOPAY_COMPANY_FORMS)

export const ELOPAGE_CONNECT_COMPANY_FORMS = {
  individual: 'individual',
  company: 'company',
  nonProfit: 'non_profit',
}
export const ELOPAGE_CONNECT_COMPANY_FORMS_LIST = Object.values(ELOPAGE_CONNECT_COMPANY_FORMS)

export const HEAD_QUARTER_USER_PROFILE_COMMON_FIELDS = [
  'city',
  'countryCode',
  'street',
  'streetNumber',
  'vatPayer',
  'zip',
  'region',
  'registrationLocation',
]

export const HEAD_QUARTER_USER_PROFILE_EXTENDED_FIELDS = ['company', ...HEAD_QUARTER_USER_PROFILE_COMMON_FIELDS]

export const LEGAL_REPRESENTATIVE_USER_PROFILE_FIELDS = [...LEGITIMATION_USER_PROFILE_FIELDS, 'phone']

export const UBO_USER_PROFILE_FIELDS = [
  ...LEGITIMATION_USER_PROFILE_FIELDS,
  'birthplaceCity',
  'birthplaceCountry',
  'region',
]

export const MANGOPAY_LEGITIMATION_SOLE_TRADER_DOCUMENTS = ['id_verification', 'company_lr']
export const MANGOPAY_LEGITIMATION_ORGANIZATION_DOCUMENTS = [
  ...MANGOPAY_LEGITIMATION_SOLE_TRADER_DOCUMENTS,
  'other_doc_1',
]
export const MANGOPAY_LEGITIMATION_DOCUMENTS = [
  ...MANGOPAY_LEGITIMATION_ORGANIZATION_DOCUMENTS,
  'shareholder_declaration',
]
export const getDocumentStatusesLocales = () => ({
  accepted: I18n.t('react.cabinet.payment_methods.document_statuses.accepted'),
  created: I18n.t('react.cabinet.payment_methods.document_statuses.created'),
  expired: I18n.t('react.cabinet.payment_methods.document_statuses.expired'),
  init: I18n.t('react.cabinet.payment_methods.document_statuses.init'),
  not_accepted: I18n.t('react.cabinet.payment_methods.document_statuses.not_accepted'),
  not_verified: I18n.t('react.cabinet.payment_methods.document_statuses.not_verified'),
  on_hold: I18n.t('react.cabinet.payment_methods.document_statuses.on_hold'),
  refused: I18n.t('react.cabinet.payment_methods.document_statuses.refused'),
  sent: I18n.t('react.cabinet.payment_methods.document_statuses.sent'),
  unreadable: I18n.t('react.cabinet.payment_methods.document_statuses.unreadable'),
  validated: I18n.t('react.cabinet.payment_methods.document_statuses.validated'),
  validation_asked: I18n.t('react.cabinet.payment_methods.document_statuses.validation_asked'),
  wrong_name: I18n.t('react.cabinet.payment_methods.document_statuses.wrong_name'),
  wrong_type: I18n.t('react.cabinet.payment_methods.document_statuses.wrong_type'),
})

export const PAYMENT_MATRIX_RELEASE_DATE = moment('2020-07-16T12:14:36.555Z', moment.ISO_8601)

/** admin options */
export const SOFORT_PROVIDER_OPTIONS = [
  {
    //   label: I18n.t('react.cabinet.payment_methods.payment_providers.lemonway'),
    //   value: PAYMENT_PROVIDERS.lemonWay
    // }, {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.mango_pay'),
    value: PAYMENT_PROVIDERS.mangoPay,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
    value: PAYMENT_PROVIDERS.stripe,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
    value: PAYMENT_PROVIDERS.elopageConnect,
  },
]

export const CARD_PROVIDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.lemonway'),
    value: PAYMENT_PROVIDERS.lemonWay,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.mango_pay'),
    value: PAYMENT_PROVIDERS.mangoPay,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
    value: PAYMENT_PROVIDERS.stripe,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
    value: PAYMENT_PROVIDERS.elopageConnect,
  },
]

export const BANK_WIRE_PROVIDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.lemonway'),
    value: PAYMENT_PROVIDERS.lemonWay,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.mango_pay'),
    value: PAYMENT_PROVIDERS.mangoPay,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.direct_bank'),
    value: PAYMENT_SYSTEMS.directBank,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
    value: PAYMENT_PROVIDERS.elopageConnect,
  },
]

export const SEPA_PROVIDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
    value: PAYMENT_PROVIDERS.stripe,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.mango_pay'),
    value: PAYMENT_PROVIDERS.mangoPay,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
    value: PAYMENT_PROVIDERS.elopageConnect,
  },
]

export const PAY_PAL_PROVIDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_rest'),
    value: PAYPAL_PROVIDERS.rest,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_nvp'),
    value: PAYPAL_PROVIDERS.nvp,
  },
]

export const P_24_PROVIDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
    value: PAYMENT_PROVIDERS.stripe,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
    value: PAYMENT_PROVIDERS.elopageConnect,
  },
]

export const IDEAL_PROVIDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
    value: PAYMENT_PROVIDERS.stripe,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
    value: PAYMENT_PROVIDERS.elopageConnect,
  },
]

export const KLARNA_PROVIDER_OPTIONS = [
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
    value: PAYMENT_PROVIDERS.stripe,
  },
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
    value: PAYMENT_PROVIDERS.elopageConnect,
  },
]

export const PAYMENT_SETTING_PROFILE_TYPE_OPTIONS = [
  {
    label: 'SELLER',
    value: PAYMENT_PROFILE_TYPES.seller,
  },
  {
    label: 'RESELLER',
    value: PAYMENT_PROFILE_TYPES.reseller,
  },
  {
    label: 'PAYER',
    value: PAYMENT_PROFILE_TYPES.payer,
  },
]

export const REGISTERED_PROVIDER_STATUSES = ['registered', 'activated']
export const PENDING_PROVIDER_STATUSES = ['not_sent', 'waiting']
export const REJECTED_PROVIDER_STATUSES = ['rejected', 'rejected_by_bank', 'by_lw', 'deactivated']

export const DEFAULT_VIBAN_COUNTRY = 'DE'
export const STRIPE_CARD = 'stripeCard'
export const STRIPE_CARD_AMEX = 'stripeCardAmEx'

export const PAYMENT_METHOD_PROVIDER_KEYS = [
  'cardProvider',
  'applePayProvider',
  'googlePayProvider',
  'p24Provider',
  'sofortProvider',
  'bankWireProvider',
  'payPalProvider',
  'sepaProvider',
  'idealProvider',
  'klarnaProvider',
]

export const PAYMENT_METHODS_WITHOUT_KLARNA = [
  PAYMENT_FORMS.paypal,
  PAYMENT_FORMS.card,
  PAYMENT_FORMS.bankWire,
  PAYMENT_FORMS.sepa,
  PAYMENT_FORMS.sofort,
  PAYMENT_FORMS.applePay,
  PAYMENT_FORMS.googlePay,
  PAYMENT_FORMS.p24,
  PAYMENT_FORMS.payLater,
  PAYMENT_FORMS.ideal,
]

export const PAYPAL_ENABLED_FUNDINGS = ['paylater']

export const PAYPAL_DISABLED_FUNDINGS = [
  'venmo',
  'applepay',
  'itau',
  'card',
  'ideal',
  'sepa',
  'bancontact',
  'giropay',
  'sofort',
  'eps',
  'mybank',
  'p24',
  'payu',
  'blik',
  'trustly',
  'zimpler',
  'maxima',
  'oxxo',
  'boletobancario',
  'wechatpay',
  'mercadopago',
  'multibanco',
]

export const PAYPAL_SDK_BASE_CONFIG = {
  components: ['buttons', 'messages'],
  intent: 'capture',
  disableFunding: PAYPAL_DISABLED_FUNDINGS,
}
